
import useFirebaseAuth from "../hooks/firebase-auth";
import { defineComponent, ref } from "vue";
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  alertController,
  IonLabel,
  IonInput,
  IonButton,
  IonItem,
} from "@ionic/vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    IonCard,
    IonCardContent,
    IonContent,
    IonPage,
    IonLabel,
    IonInput,
    IonButton,
    IonItem,
  },
  setup() {
    const state = useFirebaseAuth();
    const router = useRouter();
    const credentials = ref<{ [key: string]: string }>({
      username: "",
      password: "",
    });

    const handleChange = (e: CustomEvent) => {
      console.log(e);
      const name: string = (e?.target as any)?.name;
      credentials.value[name as string] = e.detail.value;
    };

    /**
     *
     */
    const handleAlert = (message: string, isError = false) => {
      alertController
        .create({
          header: isError ? "Error Message" : "Notice",
          message: message,
          buttons: ["OK"],
        })
        .then((t) => t.present());
    };

    /**
     *
     *
     */
    const doLogin = async () => {
      try {
        const { username, password } = credentials.value;
        await state.login(username, password);
        router.push({name : "Home", replace: true });
      } catch (error) {
        console.log(error);
        handleAlert(error.message, true);
      }
    };
    return {
      ...state,
      router,
      credentials,
      doLogin,
      handleChange,
    };
  },
});
