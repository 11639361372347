<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div id="container">
       <ion-card>
        <ion-card-content>
          <ion-item>
            <img src=".././assets/img/logo.png" class="logoImg" alt="MyFiApp">
          </ion-item>
          <ion-item>
            <ion-label position="floating">Email</ion-label>
            <ion-input name="username" type="email" @ionChange="handleChange" />
          </ion-item>
          <ion-item>
            <ion-label position="floating">Password</ion-label>
            <ion-input
              name="password"
              type="password"
              @ionChange="handleChange"
            />
          </ion-item>
          <ion-button @click="() => router.push('/register')">REGISTER</ion-button>
          <ion-button @click="doLogin">LOGIN</ion-button>
        </ion-card-content>
       </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import useFirebaseAuth from "../hooks/firebase-auth";
import { defineComponent, ref } from "vue";
import {
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  alertController,
  IonLabel,
  IonInput,
  IonButton,
  IonItem,
} from "@ionic/vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    IonCard,
    IonCardContent,
    IonContent,
    IonPage,
    IonLabel,
    IonInput,
    IonButton,
    IonItem,
  },
  setup() {
    const state = useFirebaseAuth();
    const router = useRouter();
    const credentials = ref<{ [key: string]: string }>({
      username: "",
      password: "",
    });

    const handleChange = (e: CustomEvent) => {
      console.log(e);
      const name: string = (e?.target as any)?.name;
      credentials.value[name as string] = e.detail.value;
    };

    /**
     *
     */
    const handleAlert = (message: string, isError = false) => {
      alertController
        .create({
          header: isError ? "Error Message" : "Notice",
          message: message,
          buttons: ["OK"],
        })
        .then((t) => t.present());
    };

    /**
     *
     *
     */
    const doLogin = async () => {
      try {
        const { username, password } = credentials.value;
        await state.login(username, password);
        router.push({name : "Home", replace: true });
      } catch (error) {
        console.log(error);
        handleAlert(error.message, true);
      }
    };
    return {
      ...state,
      router,
      credentials,
      doLogin,
      handleChange,
    };
  },
});
</script>

<style scoped>
/* ion-item {
  --padding-start: 0px;
} */
#container {
  text-align: center;
  max-width: 80%;
  position: absolute;
  left: 0%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}
#container strong {
  font-size: 20px;
  line-height: 26px;
}
#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}
#container a {
  text-decoration: none;
}

.logoImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  margin-bottom: 20px;
}
</style>